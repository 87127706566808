import React, { useRef } from "react";
import loadable from "@loadable/component";
import { StaticImage } from "gatsby-plugin-image";

const Xarrow = loadable(() => import("react-xarrows"));

const FMSDiagram = () => {
  const box1Ref = useRef(null);
  return (
    <>
      {/* Diagram */}
      <div className="flex flex-row items-stretch justify-between mt-16">
        <div className="w-full max-w-[340px] p-6 relative">
          {/* Absolute Background */}
          <div className="absolute inset-0 z-0 bg-gray-800 rounded-lg bottom-[6vh]"></div>

          <div className="relative z-10 text-sm text-white ">
            Connected Sensors
          </div>
          <div className="relative z-10 mt-6 list-of-sensor">
            <div className="flex items-center justify-between px-4 py-4 bg-gray-100 rounded-lg shadow-xl">
              <div>
                <p className="font-medium leading-none text-bluetext">
                  Seat Belt Sensor
                </p>
              </div>
              <div className="flex justify-end w-8 bg-green-500 rounded-full shadow-inner">
                <div className="w-4 h-4 bg-white border border-green-500 rounded-full shadow"></div>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 py-4 mt-4 bg-gray-100 rounded-lg shadow-xl">
              <div>
                <p className="font-medium leading-3 text-bluetext">
                  Fuel Level Sensor
                </p>
                <span className="text-xs leading-none leading-3 text-gray-600">
                  Tank Volume: 181 Litres
                </span>
              </div>
              <div className="flex justify-end w-8 bg-green-500 rounded-full shadow-inner">
                <div className="w-4 h-4 bg-white border border-green-500 rounded-full shadow"></div>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 py-4 mt-4 bg-gray-100 rounded-lg shadow-xl">
              <div>
                <p className="font-medium leading-3 text-bluetext">
                  Rotational Sensor
                </p>
                <span className="text-xs leading-none leading-3 text-gray-600">
                  Inactive
                </span>
              </div>
              <div className="flex w-8 bg-gray-100 rounded-full shadow-inner">
                <div className="w-4 h-4 bg-white border border-gray-100 rounded-full shadow"></div>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 py-4 mt-4 bg-gray-100 rounded-lg shadow-xl">
              <div>
                <p className="font-medium leading-3 text-bluetext">
                  PTO Sensor
                </p>
              </div>
              <div className="flex justify-end w-8 bg-green-500 rounded-full shadow-inner">
                <div className="w-4 h-4 bg-white border border-green-500 rounded-full shadow"></div>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 py-4 mt-4 bg-gray-100 rounded-lg shadow-xl">
              <div>
                <p className="font-medium leading-3 text-bluetext">
                  RFID Sensor
                </p>
              </div>
              <div className="flex w-8 bg-gray-100 rounded-full shadow-inner">
                <div className="w-4 h-4 bg-white border border-gray-100 rounded-full shadow"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={box1Ref}
          className="w-full max-w-[400px] p-6 bg-gray-100 rounded-lg relative self-start"
        >
          <div className="flex items-center justify-between">
            <div className="text-xl font-medium text-blue-800 ">
              Sensor Reports
            </div>
            <div className="text-xs text-gray-600">
              <span className="mr-1 underline">Daily</span>
              <span>Monthly</span>
            </div>
          </div>

          <div className="flex items-center justify-between px-4 py-4 mt-6 bg-white border-2 border-blue-800 rounded-lg shadow-xl">
            <div>
              <p className="font-medium leading-none text-blue-800">
                Alert: Fuel Theft
              </p>
            </div>
            <div className="text-gray-400 text-xss">9 March</div>
          </div>

          <div className="flex items-center justify-between px-4 py-4 mt-6 bg-white border-2 border-blue-800 rounded-lg shadow-xl">
            <div className="flex items-center">
              <div className="relative w-12 h-12 mr-2">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/fuq.png"
                  objectFit="cover"
                  className="!absolute inset-0"
                />
              </div>
              <div>
                <p className="font-medium text-blue-800">William Chia</p>
                <p className="text-sm text-gray-900">ID Type: Biometric</p>
              </div>
            </div>
            <div className="biometric-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="h-10 text-bluetext"
                viewBox="0 0 512 512"
              >
                <g>
                  <g>
                    <path
                      d="M331.591,325.888c-6.459,13.396-13.934,26.323-22.386,38.692c7.323,21.352,19.463,40.915,35.881,57.333
			c14.269,14.268,30.91,25.309,49.038,32.764c7.569-7.572,14.515-15.765,20.768-24.487
			C369.514,415.703,335.761,374.947,331.591,325.888z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M391.264,315.616V195.382c0-74.585-60.68-135.264-135.264-135.264s-135.264,60.68-135.264,135.264
			c0,33.149-26.969,60.117-60.117,60.117v30.059c49.723,0,90.176-40.453,90.176-90.176c0-58.01,47.195-105.205,105.206-105.205
			s105.206,47.195,105.206,105.205v120.235c0,42.71,29.846,78.579,69.777,87.849c4.53-9.091,8.38-18.576,11.466-28.398
			C413.522,370.738,391.264,345.73,391.264,315.616z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M323.831,443.168c-10.068-10.068-18.92-21.276-26.309-33.312l-10.006-16.296l-14.307,14.412
			c-34.521,34.521-75.991,59.586-121.263,73.941c11.64,7.353,24.1,13.518,37.22,18.309c33.822-14.252,65.251-34.193,93.072-59.364
			c6.177,8.321,12.98,16.206,20.339,23.564c11.136,11.136,23.303,20.867,36.285,29.109c10.523-4.949,20.541-10.799,29.94-17.469
			C352.408,467.608,337.244,456.581,323.831,443.168z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M256,0C148.267,0,60.618,87.648,60.618,195.382v30.059c16.574,0,30.059-13.484,30.059-30.059
			c0-91.159,74.164-165.323,165.323-165.323s165.323,74.164,165.323,165.323v120.235c0,15.85,12.334,28.865,27.908,29.974
			c1.412-9.455,2.151-19.128,2.151-28.972V195.382C451.382,87.648,363.733,0,256,0z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M256,120.235c-41.436,0-75.147,33.711-75.147,75.147c0,66.297-53.937,120.235-120.235,120.235v1.002
			c0,9.863,0.741,19.556,2.159,29.031c39.366-0.55,76.274-16.108,104.137-43.971c28.371-28.372,43.997-66.122,43.997-106.297
			c0-24.862,20.227-45.088,45.088-45.088s45.088,20.227,45.088,45.088c0,119.534-87.674,218.963-202.092,237.394
			c6.964,9.387,14.749,18.127,23.246,26.121c48.753-11.285,93.483-35.95,129.711-72.179
			c51.068-51.069,79.193-119.019,79.193-191.336C331.147,153.946,297.436,120.235,256,120.235z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M281.321,485.678c-0.914-0.913-1.821-1.835-2.72-2.764c-13.646,10.381-27.972,19.74-42.901,28.036
			c6.674,0.692,13.445,1.051,20.3,1.051c16.681,0,32.878-2.106,48.344-6.058C296.278,499.689,288.571,492.926,281.321,485.678z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M256,180.352c-8.3,0-15.029,6.729-15.029,15.029c0,96.403-76.031,175.381-171.277,180.125
			c3.209,10.132,7.223,19.908,11.968,29.255c48.289-4.755,93.049-25.839,127.773-60.563c39.72-39.721,61.594-92.572,61.594-148.817
			C271.029,187.081,264.3,180.352,256,180.352z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <div className="flex items-center justify-between px-4 py-4 mt-6 bg-white border-2 border-blue-800 rounded-lg shadow-xl">
            <div>
              <p className="font-medium leading-none text-blue-800">
                Alert: Outside Work Hour
              </p>
            </div>
          </div>

          <div className="flex items-center justify-end mt-12 ">
            <div className="inline-flex justify-center w-3/4 px-6 py-4 border-2 rounded-full shadow-xl border-primary">
              <p className="font-medium leading-none text-primary">
                Download Report
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 text-bluegray">
          <div className="col-span-1">
            <div
              id="elem5"
              className="flex items-center justify-center w-20 h-20 bg-gray-300 rounded-lg"
            >
              <h5 className="text-xl font-bold text-blue-800">CSV</h5>
            </div>
          </div>
          <div className="col-span-1">
            <div
              id="elem6"
              className="flex items-center justify-center w-20 h-20 bg-gray-300 rounded-lg"
            >
              <h5 className="text-xl font-bold text-blue-800">Email</h5>
            </div>
          </div>
          <div className="col-span-1">
            <div
              id="elem7"
              className="flex items-center justify-center w-20 h-20 bg-gray-300 rounded-lg"
            >
              <h5 className="text-xl font-bold text-blue-800">SMS</h5>
            </div>
          </div>
          <div className="col-span-1">
            <div
              id="elem8"
              className="flex items-center justify-center w-20 h-20 bg-gray-300 rounded-lg"
            >
              <h5 className="text-xl font-bold text-blue-800">API</h5>
            </div>
          </div>
        </div>

        <Xarrow
          start={box1Ref}
          end="elem5"
          startAnchor="right"
          endAnchor="left"
          showHead={false}
          strokeWidth={9}
          curveness={1}
          animateDrawing
          className="text-purple-600 animate-pulse"
          arrowBodyProps={{
            fill: "transparent",
            stroke: "currentColor",
          }}
        />
        <Xarrow
          start={box1Ref}
          end="elem6"
          startAnchor="right"
          endAnchor="left"
          showHead={false}
          strokeWidth={9}
          curveness={1}
          animateDrawing
          className="text-purple-600 animate-pulse"
          arrowBodyProps={{
            fill: "transparent",
            stroke: "currentColor",
          }}
        />
        <Xarrow
          start={box1Ref}
          end="elem7"
          startAnchor="right"
          endAnchor="left"
          showHead={false}
          strokeWidth={9}
          curveness={1}
          animateDrawing
          className="text-purple-600 animate-pulse"
          arrowBodyProps={{
            fill: "transparent",
            stroke: "currentColor",
          }}
        />
        <Xarrow
          start={box1Ref}
          end="elem8"
          startAnchor="right"
          endAnchor="left"
          showHead={false}
          strokeWidth={9}
          curveness={1}
          animateDrawing
          className="text-purple-600 animate-pulse"
          arrowBodyProps={{
            fill: "transparent",
            stroke: "currentColor",
          }}
        />
      </div>
      {/* Explanation */}
      <div className="flex flex-row items-stretch justify-between mt-8">
        <div className="w-full max-w-[340px] p-6 relative flex text-white text-xs items-center">
          <div className="px-3 py-2 mr-4 border border-white rounded-full">
            1
          </div>
          <div>
            Connect additional sensors to retrieve more data from your fleet of
            vehicles.
          </div>
        </div>
        <div className="w-full max-w-[400px] p-6 relative flex text-white text-xs items-center">
          <div className="px-3 py-2 mr-4 border border-white rounded-full">
            2
          </div>
          <div>
            View sensor data in real-time on KATSANA Fleet Platform. Important
            events are automatically logged for easy retrieval in reports.
          </div>
        </div>
        <div className="relative flex items-center w-full p-6 text-xs text-white">
          <div className="px-3 py-2 mr-4 border border-white rounded-full">
            3
          </div>
          <div>
            Download and export reports in PDF, CSV. Receive reports via Email,
            SMS or integrate with KATSANA Platform API.
          </div>
        </div>
      </div>
    </>
  );
};

export default FMSDiagram;
